import { useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";

function Menu() {
  const menuItems = [
    { name: "Hem", id: "home" },
    { name: "Drinkar", id: "drinks" },
    { name: "Gästbok", id: "guestBook" },
    { name: "Spotify", id: "spotify" },
    { name: "Mat", id: "food" },
  ];
  const [open, setOpen] = useState(false);
  const xIcon =
    "https://upload.wikimedia.org/wikipedia/en/thumb/6/61/Cross_icon_%28white%29.svg/1024px-Cross_icon_%28white%29.svg.png";
  const hamburgerIcon =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Hamburger_icon_white.svg/1024px-Hamburger_icon_white.svg.png";
  const ref = useOnclickOutside(
    () => {
      setOpen(false);
    },
    { ignoreClass: "my-ignore-class" }
  );

  return (
    <>
      <div
        className={`my-ignore-class absolute top-3 right-3 transform transition-all ${
          open ? "rotate-0" : "rotate-180"
        }`}
        onClick={() => setOpen(!open)}
      >
        <img width="24" src={open ? xIcon : hamburgerIcon} alt="handburgler" />
      </div>
      <div
        ref={ref}
        className={`origin-top fixed bg-[#f5f478] w-full top-[81px] transition-all duration-150 gap-2 flex flex-col py-5 ${
          open ? "scale-y-100" : " scale-y-0"
        }`}
      >
        {menuItems.map((item, index) => {
          return (
            <div
              key={index}
              className="text-lg font-semibold w-full text-center text-black uppercase tracking-widest"
              onClick={() => {
                document
                  .getElementById(item.id)
                  .scrollIntoView({ behavior: "smooth", top: -100 });
                setOpen(false);
              }}
            >
              {item.name}
            </div>
          );
        })}
        <a
          className="text-lg font-semibold w-full text-center text-black uppercase tracking-widest"
          href="https://www.iksu.se/globalassets/sektioner/iksu-brannboll/officiella-brannbollsregler-2016-med-bilaga.pdf"
        >
          Brännbollsregler
        </a>
      </div>
    </>
  );
}
export default Menu;
