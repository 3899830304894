import sponsors from "./assets/sponsors.png";

const Sponsors = () => {
  return (
    <div className="flex flex-col items-center w-full p-4 bg-black justify-center">
      <div className="uppercase text-sm font-bold text-white">Partners</div>
      <div className="h-3 w-px" />
      <div className="bg-white my-1 h-px w-full" />
      <img src={sponsors} alt="sponsors" className="w-[300px]" />
    </div>
  );
};

export default Sponsors;
