import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { format } from "date-fns";
import {
  collection,
  doc,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { MdClose, MdSend } from "react-icons/md";
import uniqid from "uniqid";
import MenuId from "./MenuId";

function GuestBook() {
  const q = query(
    collection(getFirestore(), "guestbook"),
    orderBy("createdAt", "desc"),
    limit(100)
  );
  const { data: entries } = useFirestoreQueryData("guestBookQuery", q, {
    subscribe: true,
  });

  const [sendModalOpen, setSendModalOpen] = useState(false);

  const [message, setMessage] = useState("");
  const [author, setAuthor] = useState("");

  const sendMessage = async () => {
    console.log("send", message, author);
    if (message === "" && author === "") {
      toast.error("Du måste fylla i ditt namn och ett meddelande");
      return;
    }

    const toastId = toast.loading("Skickar inlägg...");
    try {
      await setDoc(doc(getFirestore(), "guestbook", uniqid()), {
        message,
        author,
        createdAt: new Date(),
      });
      // setTimeout(refetch, 400);
      setSendModalOpen(false);
      toast.success("Ditt inlägg är skickat!", { id: toastId });
    } catch (e) {
      console.log(e);
      toast.error("Någonting gick fel", { id: toastId });
    }
  };

  useEffect(() => {
    if (!sendModalOpen) {
      setMessage("");
      setAuthor("");
    }
  }, [sendModalOpen]);

  return (
    <div className="flex flex-col p-2 my-6 relative">
      <MenuId id="guestBook" />
      <h1 className="text-center text-4xl uppercase italic">Gästbok</h1>
      <div className="h-2" />
      <div className="flex flex-col divide-y max-h-[300px] overflow-y-auto divide-gray-200">
        {(entries || []).map((entry, index) => (
          <div key={index} className="p-2">
            <div className="">{entry.message}</div>
            <div className="text-gray-500 text-xs flex justify-between">
              <div>{entry.author}</div>
              <div>
                {format(entry.createdAt.toDate(), "yyyy-MM-dd HH:mm:ss")}
              </div>
            </div>
          </div>
        ))}
      </div>

      <button
        className="bg-black text-white p-3 w-full font-bold uppercase rounded mt-4"
        onClick={() => setSendModalOpen(true)}
      >
        Nytt gästboksinlägg
      </button>
      {/* <div>
        {messages.map((m) => (
          <div key={m.content + m.author}>
            <p>
              Av {m.author}: {m.content}
            </p>
          </div>
        ))}
      </div> */}
      {sendModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center p-8 z-[1000] bg-black bg-opacity-50">
          <div
            onClick={() => null}
            className="bg-white rounded-lg pointer-events-all shadow-xl flex flex-col w-full max-w-2xl p-3"
          >
            <div className="flex justify-between">
              <h3>Nytt inlägg</h3>
              <button
                className="w-8 h-8 rounded-full flex items-center justify-center text-black"
                onClick={() => setSendModalOpen(false)}
              >
                <MdClose className="text-3xl" />
              </button>
            </div>

            <div>
              <label
                for="email"
                class="block text-sm font-medium text-gray-700"
              >
                Ditt namn
              </label>
              <div class="mt-0.5">
                <input
                  type="text"
                  name="name"
                  className="shadow-sm p-3 border border-gray-200 focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm rounded-md"
                  placeholder="Ange ditt namn..."
                  onChange={(e) => setAuthor(e.target.value)}
                />
              </div>
            </div>

            <div className="mt-2">
              <label
                for="email"
                class="block text-sm font-medium text-gray-700"
              >
                Meddelande
              </label>
              <div class="mt-0.5">
                <textarea
                  type="text"
                  name="name"
                  rows={4}
                  className="shadow-sm p-3 border border-gray-200 focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm rounded-md"
                  placeholder="Ange ditt namn..."
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>

            <button
              className="bg-black mt-2 text-white p-3 w-full font-bold uppercase rounded flex items-center justify-center"
              onClick={sendMessage}
            >
              <MdSend className="mr-1" /> Skicka
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default GuestBook;
