import { isAfter } from "date-fns";
import { useEffect, useRef, useState } from "react";
import MenuId from "./MenuId";
const SpotifyBanner = () => {
  const [direction, setDirection] = useState(-1);
  const dir = useRef(-1);

  useEffect(() => {
    const int = setInterval(() => {
      setDirection(dir.current);
      dir.current = dir.current === 1 ? -1 : 1;
    }, 500);

    return () => {
      clearInterval(int);
    };
  }, []);

  const handleOpenAlbum = () => {
      window.open(
        "https://open.spotify.com/album/6mD9SabEzuLQPonXxZ95t1?si=Zahw1s4MRQC-M4HVAkqK6w",
        "_blank"
      );
  };

  return (
    <div
      className="text-white cursor-pointer font-extrabold uppercase p-8 bg-black flex items-center justify-center my-4 text-center text-3xl relative"
      style={{
        fontFamily: "'Noto Sans', sans-serif",
      }}
      onClick={handleOpenAlbum}
    >
      <MenuId id="spotify" />
      <div style={{ transform: `skew(${direction * 15}deg)` }}>
        Sounds on Spotify
      </div>
    </div>
  );
};

export default SpotifyBanner;
