import "./App.css";
import edge_left from "./assets/edge_left.png";
import edge_right from "./assets/edge_right.png";
import bg from "./assets/nor24.jpeg";
import skog from "./assets/skog.svg";
import Drinklist from "./Drinklist";
import GuestBook from "./GuestBook";

import Food from "./Food";
import Menu from "./Menu";
import Presentation from "./Presentation";
import Sponsors from "./Sponsors";

import SpotifyBanner from "./SpotifyBanner";

function App() {
  return (
    <div className="min-h-screen flex flex-col">
      <header className="bg-black p-2 flex items-center justify-center sticky top-0 text-white text-xl z-50">
        <div className="flex flex-col justify-center items-center">
          <img src={skog} alt="Skog" className="w-24 -mb-2" />
          <div
            className="font-extrabold font-base italic"
            style={{ fontFamily: "'Noto Sans', sans-serif" }}
          >
            Hoppskogsfestivalen 2022
          </div>
        </div>
        <Menu />
      </header>
      <div className="flex">
        <div
          className="bg-repeat-y relative flex-none w-[40px] lg:w-[60px] min-h-screen marquee-animation-left"
          style={{
            backgroundImage: `url(${edge_left})`,
            backgroundSize: "100% auto",
            // backgroundPosition: "center top",
          }}
        />
        <div className="mx-auto w-full max-w-3xl">
          <div
            className="bg-center bg-cover w-full h-64"
            style={{ backgroundImage: `url(${bg})` }}
          />
          <Presentation />
          <div className="p-4">
            <Drinklist />
          </div>
          <GuestBook />
          <SpotifyBanner />
          <Food />
          <div className="flex items-center justify-center flex-col gap-2 mb-6 pt-5 border-t-2 border-dashed">
            <span className="font-bold">IN & FÖLJ</span>
            <a href="https://www.facebook.com/events/3666332660259684">
              <img
                width="32"
                src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Facebook_icon_%28black%29.svg"
                alt="facebook"
              />
            </a>
          </div>
          <Sponsors />
        </div>

        <div
          className="bg-repeat-y relative flex-none w-[40px] lg:w-[60px] min-h-screen marquee-animation-right"
          style={{
            backgroundImage: `url(${edge_right})`,
            backgroundSize: "100% auto",
            // backgroundPosition: "center top",
          }}
        />
      </div>
    </div>
  );
}

export default App;
