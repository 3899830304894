import MenuId from "./MenuId";
function Presentation() {
  return (
    <div className="px-5 font-semibold flex  flex-col gap-4 py-4">
      <MenuId id="home" />
      <span className="text-center underline italic">
        Välkommen Till Första Upplagan Av Hoppskogsfestivalen!
      </span>
      <span className="text-center">
        När svensk sommar är som allra bäst kommer det bjudas på livemusik i
        Hälsingland på ”Hoppa” i dansbanan.
      </span>
    </div>
  );
}

export default Presentation;
