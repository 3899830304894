// way out west comes with a food offering out of the ordinary. Everything vegetarian with a large vegan offering
// Varmrätt: Vegetarisk burrito
// Efterrätt: Churros
// Vickning: Varmkorv
import MenuId from "./MenuId";
import useScrollPosition from "./useScrollPosition";

function Food() {
  const scrollPosition = useScrollPosition();
  const size = Math.min(1, scrollPosition / 1872);
  console.log(size);
  return (
    <div className="flex flex-col w-full mt-5 mb-5 relative">
      <MenuId id="food" />
      <div className="flex-1 mb-4 overflow-hidden">
        <img
          src="https://www.goya.com/media/4175/easy-black-bean-burritos.jpg"
          alt="Burrito"
        />
      </div>
      <div className="flex-1 gap-y-1 flex flex-col justify-center items-center">
        <h2 className="text-5xl">MAT</h2>
        <div>
          <span className="text-center font-semibold px-4 w-full block mb-6">
            Hoppskogsfestivalen kommer med ett story matutbud utöver det
            vanliga. Vegetarisk mat, efterrätt och vickning i världsklass!
          </span>
          <div className="flex flex-col items-center justify-center gap-y-3 text-2xl font-bold [&>span]:underline [&>span]:font-semibold [&>span]:italic [&>span]:text-lg">
            <span>Huvudrätt</span>
            <div>🌯 Vegetarisk Burrito</div>
            <span>Efterrätt</span>
            <div>🥨 Churros</div>
            <span>Vickning</span>
            <div>🌭 Varmkorv</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Food;
